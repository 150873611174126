const ErrorIcon = () => {
  return (
    <svg
      width='16'
      height='17'
      viewBox='0 0 16 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.83398 8.50004C1.83398 3.87537 3.37598 2.33337 8.00065 2.33337C12.6253 2.33337 14.1673 3.87537 14.1673 8.50004C14.1673 13.1247 12.6253 14.6667 8.00065 14.6667C3.37598 14.6667 1.83398 13.1247 1.83398 8.50004Z'
        stroke='#F64629'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.9983 10.8333H8.0043'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.0013 5.90332V8.49999'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default ErrorIcon;
